import useQueryGraphQL from '~/core/middleware/use-query-graphQL'
import QueryCareersSetting from '../../settings/careers/graphql/query-careers-setting'

export function useQueryCareerSetting({
  variables,
  shouldPause = false
}: {
  variables: object
  shouldPause?: boolean
}) {
  const {
    trigger,
    isLoading,
    data: response,
    error
  } = useQueryGraphQL({
    query: QueryCareersSetting,
    variables,
    shouldPause
  })

  return {
    trigger,
    isLoading,
    data: response?.tenantSettingsCareerSite,
    error
  }
}
