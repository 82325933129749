import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Badge } from '~/core/ui/Badge'
import { Dialog } from '~/core/ui/Dialog'
import { TypographyText } from '~/core/ui/Text'
import {
  IJobManagementItemQuery,
  MatchedField,
  MatchedFieldTotal
} from '~/lib/features/jobs/types'
import { MATCHED_RANK_BADGE_COLOR } from '~/lib/features/jobs/utilities/enum'
import { CircleCheckFill } from '~/core/ui/FillIcons'
import { Button } from '~/core/ui/Button'
import { useTranslation } from 'react-i18next'
import { cn } from '~/core/ui/utils'
import { JobDetailType } from '~/lib/features/referrals/types'
import { JobType } from '~/lib/features/apply/jobId/types'
import { Link2, Link2Icon, LinkIcon } from 'lucide-react'
import ShareJobModal from '~/components/Jobs/ShareJobModal'
import useBoundStore from '~/lib/store'
import useReferralSetting from '~/lib/features/settings/referrals/hooks/useReferralSetting'
import { PUBLIC_APP_URL } from '~/core/constants/env'
import pathConfiguration from 'src/configuration/path'
import { combineDomainCareerPage } from '~/lib/features/careers/[id]/utilities'
import useCareerSettingStore from '~/lib/features/careers/[id]/store'
import { TextButton } from '~/core/ui/TextButton'
import { useQueryCareerSetting } from '~/lib/features/calendar/hooks/use-query-career-setting'
import useSubscriptionPlan from '~/components/Subscription/useSubscriptionPlan'
import { PLAN_FEATURE_KEYS } from '~/lib/features/settings/plans/utilities/enum'
import useShareJobLogic from '~/lib/features/jobs/hooks/use-share-job-logic'

type MatchedFieldExtraAttr = {
  helpText?: string
}

type MatchedFieldType = MatchedField & MatchedFieldExtraAttr

export type ModalMatchedRankType = {
  open: boolean
  listRecommend?: Array<MatchedFieldType | MatchedFieldTotal>
  job?: IJobManagementItemQuery | JobDetailType | JobType
  modalDetail?: {
    title?: string
    description?: string | ReactNode
  }
}

const MatchedRankDetail: FC<{
  children: (
    setModalMatchedRank?: Dispatch<SetStateAction<ModalMatchedRankType>>
  ) => ReactNode
}> = ({ children }) => {
  const { t } = useTranslation()
  const { user } = useBoundStore()
  const {
    isFeatureEnabled,
    isUnLockFeature,
    data: dataTenantSettingPlan
  } = useSubscriptionPlan()
  const { dataReferral } = useReferralSetting({
    suspend:
      !dataTenantSettingPlan ||
      !isFeatureEnabled(PLAN_FEATURE_KEYS.referral) ||
      !isUnLockFeature(PLAN_FEATURE_KEYS.referral)
  })

  const [openShareModal, setOpenShareModal] = useState<boolean>(false)

  const { data: careerResponseSetting, trigger: triggerCareerSetting } =
    useQueryCareerSetting({
      variables: {},
      shouldPause: true
    })

  const { valueShareJobWithCondition } = useShareJobLogic()

  useEffect(() => {
    triggerCareerSetting()
  }, [])
  const [modalMatchedRank, setModalMatchedRank] =
    useState<ModalMatchedRankType>({
      open: false
    })

  const checkConditionShareJob = valueShareJobWithCondition({
    enablingReferral: dataReferral?.values?.enabling || false,
    enablingCareerSite:
      user.currentTenant?.careerSiteSettings?.enablingCareerSiteSetting ||
      false,
    enableJobReferral: modalMatchedRank?.job?.jobReferable || false,
    jobStatus: modalMatchedRank?.job?.status || ''
  })

  const matchedFieldsList = useMemo(
    () =>
      modalMatchedRank?.listRecommend?.reduce(
        (result, matchedField) => {
          return {
            ...result,
            ...(matchedField.field === 'total'
              ? {
                  total: matchedField as MatchedFieldTotal
                }
              : {
                  detail: [...result.detail, matchedField as MatchedFieldType]
                })
          }
        },
        { detail: [] as MatchedFieldType[], total: {} as MatchedFieldTotal }
      ),
    [modalMatchedRank?.listRecommend]
  )

  const onCloseModal = useCallback(
    () =>
      setModalMatchedRank({
        open: false
      }),
    []
  )

  return (
    <>
      {children(setModalMatchedRank)}
      <Dialog
        className="min-w-[480px]"
        open={modalMatchedRank?.open}
        isPreventAutoFocusDialog
        label={
          <div className="flex items-center gap-x-2">
            <span>{modalMatchedRank?.modalDetail?.title}</span>
            <Badge
              color={
                MATCHED_RANK_BADGE_COLOR[
                  matchedFieldsList?.total?.total_rate_string || 'C'
                ]
              }
              size="md"
              classNameText="text-white font-medium">
              {matchedFieldsList?.total.total_rate_string} (
              {matchedFieldsList?.total?.total_rate || 0}%)
            </Badge>
          </div>
        }
        description={modalMatchedRank?.modalDetail?.description}
        onOpenChange={onCloseModal}>
        <div className="grid gap-y-5">
          {matchedFieldsList?.detail.map((matchedField, index) => (
            <div key={`matched-field-detail-${index}`}>
              <div className="mb-1.5 flex items-center gap-x-1.5">
                <TypographyText className="text-sm font-medium text-gray-900">
                  {matchedField?.label}
                </TypographyText>
                {matchedField?.helpText && (
                  <TypographyText className="text-sm text-gray-600">
                    ({matchedField?.helpText})
                  </TypographyText>
                )}
                {matchedField?.matched_rate && (
                  <TypographyText className="text-sm text-gray-600">
                    ({matchedField?.matched_rate})
                  </TypographyText>
                )}
              </div>
              <div
                className={cn(
                  'grid gap-x-4 gap-y-1.5',
                  matchedField?.data?.length > 3 ? 'grid-cols-2' : ''
                )}>
                {matchedField?.data?.map((field, index) => (
                  <div
                    key={`field-${index}`}
                    className="flex items-center gap-x-1.5">
                    {field.matched ? (
                      <CircleCheckFill
                        className={`flex-none !fill-green-500`}
                        size={20}
                      />
                    ) : (
                      <div className="mx-0.5 h-4 w-4 flex-none rounded-lg border border-gray-300" />
                    )}
                    <TypographyText className="flex-1 text-sm text-gray-700">
                      {field.label}
                    </TypographyText>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="mt-6 flex flex-1 flex-row items-center">
          <div className="flex flex-1 flex-row items-center">
            {modalMatchedRank?.job &&
            checkConditionShareJob?.recommendation?.shareBtn ? (
              <>
                <TextButton
                  underline={false}
                  size="md"
                  type="secondary"
                  onClick={() => setOpenShareModal(true)}
                  iconMenus="LinkIcon"
                  label={`${t('button:shareJob')}`}
                />
              </>
            ) : null}
          </div>
          <div className="flex justify-end">
            <Button
              size="sm"
              label={`${t('button:close')}`}
              onClick={onCloseModal}
            />
          </div>
        </div>
        {user.currentTenant?.careerSiteSettings?.canonical_url &&
        modalMatchedRank?.job ? (
          <ShareJobModal
            shareInternal={checkConditionShareJob?.shareInternal}
            sharePublic={checkConditionShareJob?.sharePublic}
            urlReferral={`${PUBLIC_APP_URL}${pathConfiguration.careerHub.jobDetail(
              {
                tenantSlug: modalMatchedRank?.job?.tenant?.slug,
                jobId: modalMatchedRank?.job?.id.toString()
              }
            )}`}
            open={openShareModal}
            setOpen={setOpenShareModal}
            url={`${combineDomainCareerPage(
              careerResponseSetting
            )}${pathConfiguration?.careers.jobDetail({
              tenantSlug: modalMatchedRank?.job?.tenant?.slug,
              jobId: modalMatchedRank?.job?.slug
            })}`}
          />
        ) : null}
      </Dialog>
    </>
  )
}

export default MatchedRankDetail
