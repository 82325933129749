import React, { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Badge } from '~/core/ui/Badge'
import { JobType } from '~/lib/features/apply/jobId/types'
import { renderSalary } from '~/lib/features/apply/jobId/utilities/helper-salary'
import {
  IJobManagementItemQuery,
  MatchedFieldTotal
} from '~/lib/features/jobs/types'
import { MATCHED_RANK_BADGE_COLOR } from '~/lib/features/jobs/utilities/enum'
import { JobDetailType } from '~/lib/features/referrals/types'
import useBoundStore from '~/lib/store'
import { ModalMatchedRankType } from './MatchedRankDetail'

const MatchedRankDetailWrapper: FC<{
  job: IJobManagementItemQuery | JobDetailType | JobType
  openMatchedRank?: Dispatch<SetStateAction<ModalMatchedRankType>>
  size?: 'sm' | 'md' | 'lg' | 'xs'
}> = ({ job, openMatchedRank, size = 'md' }) => {
  const { t } = useTranslation()
  const { user } = useBoundStore()

  const rank = useMemo(
    () =>
      (job.recommendationMatchedFields || []).find(
        (item) => item.field === 'total'
      ) as MatchedFieldTotal,
    [job]
  )
  const formatListRecommendation = useMemo(() => {
    const splashSalary = renderSalary({
      t,
      salaryFrom: Number(job?.permittedFields?.salaryFrom?.value) || 0,
      salaryTo: Number(job?.permittedFields?.salaryTo?.value) || 0,
      currency:
        (job?.permittedFields?.currency?.value as string) ||
        user?.currentTenant?.currency ||
        '',
      typeOfSalaryDescription: (
        job?.permittedFields?.typeOfSalaryDescription?.value as string
      )?.toLowerCase(),
      displayTypeOfSalaryType: 'slash'
    })
    return (job.recommendationMatchedFields || []).map((matchedField) =>
      matchedField.field !== 'salary'
        ? matchedField
        : {
            ...matchedField,
            helpText: `${t(
              'candidates:tabs:candidateRecommendation:jobSalaryHelpText',
              { salary: splashSalary }
            )}`
          }
    )
  }, [job])

  return (
    <div
      className="whitespace-nowrap"
      onClick={() => {
        openMatchedRank &&
          openMatchedRank({
            open: true,
            listRecommend: formatListRecommendation,
            job,
            modalDetail: {
              title: `${t(
                'candidates:tabs:candidateRecommendation:detailModal:title'
              )}`,
              description: (
                <Trans
                  i18nKey={
                    'candidates:tabs:candidateRecommendation:detailModal:description'
                  }
                  values={{
                    job_title: job.title
                  }}>
                  <span className="font-medium text-gray-900" />
                </Trans>
              )
            }
          })
      }}>
      <Badge
        color={MATCHED_RANK_BADGE_COLOR[rank?.total_rate_string]}
        size={size}
        classNameText="text-white font-medium hover:cursor-pointer">
        {rank?.total_rate_string} ({rank?.total_rate}%)
      </Badge>
    </div>
  )
}

export default MatchedRankDetailWrapper
