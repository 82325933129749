import { TFunction } from 'i18next'

export const renderSalary = ({
  t,
  salaryFrom,
  salaryTo,
  currency,
  typeOfSalaryDescription,
  displayTypeOfSalaryType = 'parentheses'
}: {
  t: TFunction
  salaryFrom: number
  salaryTo: number
  currency: string
  typeOfSalaryDescription?: string
  displayTypeOfSalaryType?: 'parentheses' | 'slash'
}): string => {
  const salaryTypeString = typeOfSalaryDescription
    ? displayTypeOfSalaryType === 'parentheses'
      ? ` (${typeOfSalaryDescription})`
      : `/${typeOfSalaryDescription}`
    : ''
  if (
    (salaryFrom === null && salaryTo === null) ||
    (salaryFrom === 0 && salaryTo === 0)
  )
    return `${t('label:viewSalary:negotiableSalary')}`
  if (salaryFrom > 0 && salaryTo > 0)
    return `${salaryFrom.toLocaleString('en-US')} - ${salaryTo.toLocaleString(
      'en-US'
    )} ${currency}${salaryTypeString}`
  if (salaryTo > 0)
    return `${t('label:viewSalary:salaryTo', {
      salaryTo: `${salaryTo.toLocaleString('en-US')} ${currency}`
    })}${salaryTypeString}`
  if (salaryFrom > 0)
    return `${t('label:viewSalary:salaryFrom', {
      salaryFrom: `${salaryFrom.toLocaleString('en-US')} ${currency}`
    })}${salaryTypeString}`
  return ''
}
